import * as React from 'react'
import Icon, { Props as IconProps } from './Icon'
import { CmpStyles } from '../providers/style/types'
import View from './View'

const styles: CmpStyles = {
  box: ({ isPaused }) => ({
    animation: 'rotation 1s infinite linear',
    animationPlayState: isPaused ? 'paused' : 'running',
    willChange: 'transform',
  }),
  screen: ({ theme: { components } }) => ({
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    ...components.Spinner.Screen,
  }),
}

type Props = {
  scale?: number
  isPaused?: boolean
  color?: IconProps['color']
}

const Spinner = ({ scale = 0, isPaused, color }: Props) => (
  <Icon
    name="loader-circle"
    color={color}
    style={styles.box}
    scale={scale}
    isPaused={isPaused}
  />
)

Spinner.Screen = () => (
  <View style={styles.screen}>
    <Spinner scale={3} />
  </View>
)

export default Spinner
