import * as React from 'react'
import cmpStyles from '@packages/common/src/providers/style/cmp-styles'
import {
  getBorderRadiusStyleInInputsGroup,
  getMarginStyleInInputsGroup,
} from '@packages/common/src/components/InputsGroup'
import UnstyledSingleLineInput, {
  Props as InputPureProps,
} from './UnstyledSingleLineInput'

export type Props = InputPureProps & {
  leftSpace?: number
  rightSpace?: number
}

const styles = cmpStyles({
  box({
    leftSpace = 0,
    rightSpace = 0,
    ctx: { readOnly, disabled, variant, withError },
    theme: { borderRadius, components },
  }) {
    const errorState = withError ? 'error' : 'normal'
    const borderRadiusBase = variant === 'compact' ? 0 : borderRadius.normal

    return {
      display: 'inline-block', // default value, don't mess with input
      width: '100%',
      maxWidth: '100%',
      ...getMarginStyleInInputsGroup(1), // place for boxShadow
      paddingLeft: (variant === 'compact' ? 4 : 9) + leftSpace,
      paddingRight: (variant === 'compact' ? 4 : 9) + rightSpace,
      paddingVertical: variant === 'compact' ? 2 : 5,
      border: 'none',
      ...getBorderRadiusStyleInInputsGroup(borderRadiusBase),
      fontFamily: 'lato',
      fontSize: variant === 'compact' ? 13 : 15,
      lineHeight: variant === 'compact' ? '18px' : '22px',
      cursor: readOnly || disabled ? 'default' : 'text',
      ...components.TextInput.input[errorState],
      '@media (max-width: 540px)': {
        fontSize: 16,
      },
    }
  },
})

const InputSingleLine = React.forwardRef<HTMLInputElement, Props>(
  (props, ref) => (
    <UnstyledSingleLineInput style={styles.box} ref={ref} {...props} />
  )
)

export default InputSingleLine
